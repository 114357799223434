<template>
  <div class="checkOrder">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="订单详情"
      top="5vh"
      :visible.sync="isshowDialog"
      width="1160px"
      @close="close"
    >
      <div v-if="form" v-loading="loading" class="wrapper">
        <div
          style="
            width: 86%;
            margin-top: -70px;
            margin-left: 80px;
            cursor: pointer;
          "
          @click="handlerOldcoed"
        >
          <el-alert :closable="false" :title="titleTip" type="success">
            <span>{{ form.bill_type_text }}</span>
          </el-alert>
        </div>

        <!-- <div class="orderStatus">{{ orderStatus }}</div> -->
        <order-status :status="orderStatusStr"></order-status>
        <el-form ref="form" :model="form" label-width="80px" :inline="true">
          <el-form-item label="购货方" prop="cust_name">
            <el-input v-model.trim="form.cust_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="boss">
            <el-input v-model.trim="form.boss" disabled></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address" class="dz">
            <el-input
              v-model.trim="form.address"
              disabled
              style="width: 380px"
            ></el-input>
          </el-form-item>

          <el-form-item label="仓库" prop="chck">
            <el-input v-model.trim="chck" disabled></el-input>
          </el-form-item>

          <el-form-item label="送货人" prop="deliver_name">
            <el-input v-model.trim="form.deliver_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="业务员" prop="staff_name">
            <el-input v-model.trim="form.staff_name" disabled></el-input>
          </el-form-item>

          <el-form-item label="调拨单" prop="allot_code">
            <el-input v-model.trim="form.allot_code" disabled></el-input>
          </el-form-item>
          <el-form-item label="派单" prop="delivery_time">
            <el-input v-model.trim="form.delivery_time+form.deliver_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="到货" prop="arrive_time">
            <el-input v-model.trim="form.arrive_time+form.arrive_name" disabled></el-input>
          </el-form-item>
        </el-form>
        <div class="setFilter">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </div>
        <el-table
          ref="middleTable"
          stripe
          :data="middleTableData"
          show-summary
          :summary-method="summaryMethod"
        >
          <!-- 序号 -->
          <el-table-column prop="" align="center" label="" width="60px">
            <template #default="{ $index }">
              <span>{{ $index + 1 }}</span>
            </template>
          </el-table-column>
          <!-- 选择框 -->
          <el-table-column
            align="center"
            type="selection"
            width="45"
          ></el-table-column>
          <el-table-column
            v-for="(item, index) in finallyColumns"
            :key="index"
            align="center"
            :label="item.label"
            :sortable="item.sortable"
            width=""
          >
            <template v-if="item.label == '类型'" #default="{ row }">
              <span>{{ row.goods_type_text }}</span>
              <!-- <el-input
                v-model="row.goods_type_text"
                disabled
                style="width: 100px"
              ></el-input> -->
              <!-- <el-select
                v-model="row.goods_type"
                disabled
                placeholder="请选择类型"
              >
                <el-option
                  v-for="item__ in row.arr_goods_type"
                  :key="item__.id"
                  :label="item__.name"
                  :value="item__.id"
                ></el-option>
              </el-select> -->
            </template>
            <!-- <template v-else-if="item.label == '商品名称'" #default="{ row }">
              {{ row[item.prop] }}
              <span v-if="row.is_abnormal == 2" style="color: #f60">异常</span>
            </template> -->
            <template v-else-if="item.label == '商品名称'" #default="{ row }">
              {{ row[item.prop] }}
              <span v-if="row.is_abnormal == 2" style="color: #f60">
                {{ row.is_abnormal_type_text }}
              </span>
            </template>
            <template v-else-if="item.label == '条码'" #default="{ row }">
              {{ currUnitCode(row) }}
            </template>
            <template v-else #default="{ row }">
              <span>{{ row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 底部输入框 -->
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
          :inline="true"
          style="margin-top: 15px"
        >
          <div style="width: 100%">
            <el-form-item label="备注" prop="remark">
              <div style="display: flex">
                <el-input
                  v-model.trim="form.remark"
                  style="width: 200px; margin-right: 10px"
                ></el-input>
                <el-button type="primary" @click="handleSaveRemark">
                  保存备注
                </el-button>
              </div>
            </el-form-item>
            <el-form-item label="客户签名" prop="">
              <el-image
                v-if="form.sign_url"
                style="height: 36px"
                :preview-src-list="[form.sign_url]"
                :src="form.sign_url"
              ></el-image>
            </el-form-item>
          </div>
          <!--          <template v-if="style == 1">-->
          <el-form-item label="订购总额" prop="total_amount">
            <el-input v-model.trim="form.total_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="销售金额" prop="sale_amount">
            <el-input v-model.trim="form.sale_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="使用预存款" prop="deposit_amount">
            <el-input
              v-model.trim="form.deposit_amount"
              disabled
              style="width: 110px"
            ></el-input>
            余额
            <el-input
              v-model="form.cust_deposit_left"
              disabled
              style="width: 100px"
            ></el-input>
          </el-form-item>
          <el-form-item label="还货金额" prop="return_amount">
            <el-input v-model.trim="form.return_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="优惠后" prop="discounted_amount">
            <el-input v-model.trim="form.discounted_amount" disabled></el-input>
          </el-form-item>
          <div></div>
          <el-form-item label="退货金额" prop="refund_goods_amount">
            <el-input
              v-model.trim="form.refund_goods_amount"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="退货方式" prop="refund_type_text">
            <el-input v-model="form.refund_type_text" disabled></el-input>
          </el-form-item>
          <el-form-item label="退货退款" prop="refund_amount">
            <el-input v-model.trim="form.refund_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="费用抵扣" prop="support_amount">
            <el-input v-model.trim="form.support_amount" disabled></el-input>
          </el-form-item>
          <div></div>
          <el-form-item label="应收款" prop="receiv_money">
            <el-input v-model.trim="form.receiv_money" disabled></el-input>
          </el-form-item>
          <!--          </template>-->
          <el-form-item
            v-if="style == 1"
            label="结算方式"
            prop="account_type_text"
          >
            <el-input v-model.trim="form.account_type_text" disabled></el-input>
          </el-form-item>
          <el-divider></el-divider>
          <div v-if="style == 1" class="style1">
            <el-form-item label="销售收款:">
              <div style="display: flex">
                <el-input v-model.trim="form.staff_collect" disabled></el-input>
              </div>
            </el-form-item>
            <el-form-item label="派送收款:">
              <el-input v-model.trim="form.deliver_collect" disabled></el-input>
            </el-form-item>
            <el-form-item label="订单毛利:">
              <el-input
                v-model.trim="form.gross_profit_amount"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="毛利率:">
              <el-input v-model.trim="form.gross_profit" disabled></el-input>
            </el-form-item>
            <el-form-item label="欠款:">
              <el-input v-model.trim="form.left_amount" disabled></el-input>
            </el-form-item>
            <el-form-item label="经办人:">
              <el-input v-model.trim="form.jingbanren" disabled></el-input>
            </el-form-item>
          </div>
          <div v-else-if="style == 2">
            <el-form-item label="兑奖现金:" prop="prize_amount">
              <el-input
                v-model.trim="form.prize_amount"
                @input="rehandler"
              ></el-input>
            </el-form-item>
          </div>
          <div v-else-if="style == 3">
            <el-form-item label="兑付现金:" prop="cash_amount">
              <el-input
                v-model.trim="form.cash_amount"
                @input="rehandler"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>

        <!-- 小表格 -->
        <!-- v-show="xTableData.length" -->

        <el-table ref="xTable" stripe :data="xTableData" show-summary>
          <el-table-column
            align="center"
            label="类型"
            width="auto"
            prop="type"
          ></el-table-column>
          <el-table-column
            align="center"
            label="收款方式"
            width="auto"
            prop="pay_type_name"
          ></el-table-column>
          <el-table-column
            align="center"
            label="金额"
            width="auto"
            prop="amount"
          ></el-table-column>
          <el-table-column
            align="center"
            label="收款人"
            width="auto"
            prop="user_name"
          ></el-table-column>
          <el-table-column
            align="center"
            label="时间"
            width="auto"
            prop="create_at"
          ></el-table-column>
        </el-table>
        <div class="bottom-form">
          <div class="tit">相关单据:</div>
          <div v-for="order in bottomData" :key="order.id">
            {{ order.name }}:
            <span
              class="under-line"
              @click="hanldeOrderDetail(order.name, order.id)"
            >
              {{ order.code }}
            </span>
            <span v-for="item in order.detail" :key="item.id">
<!--              <span
                class="under-line"
                @click="hanldeOrderDetail('收款单', item.id)"
              >
                {{ item.code }} ({{ item.name }})
              </span>-->
              <span
                class="under-line"
                @click="hanldeOrderDetail(order.name, item.id)"
              >
                {{ item.code }} ({{ item.name }})
              </span>
            </span>
          </div>
          <!-- <div v-show="bottomData.bill_code" class="b-item">
            预订单:
            {{ bottomData.bill_code == '' ? '' : bottomData.bill_code }}
          </div>
          <div v-show="bottomData.allot_code" class="b-item">
            调拨单:
            {{ bottomData.allot_code == '' ? '' : bottomData.allot_code }}
          </div>
          <div
            v-show="
              bottomData.sale_code &&
              bottomData.delivery_code &&
              bottomData.clearing_code
            "
            class="b-item"
          >
            收款单:
            {{ bottomData.sale_code == '' ? '' : bottomData.sale_code }}
            、{{
              bottomData.delivery_code == '' ? '' : bottomData.delivery_code
            }}
            、{{
              bottomData.clearing_code == '' ? '' : bottomData.clearing_code
            }}
          </div> -->
          <!-- <el-button class="checkAll" @click="checkAll">查看全部单据</el-button> -->
        </div>
        <!-- 底部按钮 -->
        <div
          slot="footer"
          class="dialog-footer"
          style="margin-top: 5px; text-align: right"
        >
          <el-button @click="handlePrint">打印</el-button>
          <el-button @click="close">取 消</el-button>
        </div>
      </div>
      <ruilang-display-dialog
        ref="RuilangDisplayDialog"
        bill_type="XS"
        :data_id="id"
        :type="1"
      ></ruilang-display-dialog>

      <advanceOrder ref="advanceOrder"></advanceOrder>
      <diaobodan-order ref="diaobodanOrder"></diaobodan-order>
      <shoukuanOrder ref="shoukuanOrder"></shoukuanOrder>
      <sale-order-detail ref="checkOrder"></sale-order-detail>
      <SaleOrderDetail ref="check"/>
    </el-dialog>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { getDetail, saveRemark } from '@/api/saleOrder'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import OrderStatus from '@/baseComponents/orderStatus'

  import advanceOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  import diaobodanOrder from '@/views/project/treasury/requisition/documents/components/AddGoods.vue'
  import shoukuanOrder from '@/views/project/finance/client/receipt/compontents/InfoReceipt.vue'
  import { receiv } from '@/utils/AOform'
  import SaleOrderDetail from "@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue";
  export default {
    name: 'SaleOrderDetail',
    components: {
      SaleOrderDetail,
      OrderStatus,
      RuilangDisplayDialog,
      advanceOrder,
      diaobodanOrder,
      shoukuanOrder,
    },
    data() {
      return {
        isshowDialog: false,
        loading: true,
        style: 1,
        id: 0,
        // 单号
        orderCode: '',
        // 下单时间
        orderTime: '',
        form: undefined,
        title: '订单详情',
        orderStatus: '状态',
        bottomData: null,
        total_num: null,
        total_money: null,
        // 中间表格
        checkList: [
          '类型',
          '商品名称',
          '规格',
          '条码',
          '单位',
          '单价',
          '数量',
          '金额',
          '毛利',

          '生产日期',
          '备注',
        ],
        columns: [
          {
            order: 2,
            label: '类型',
            width: 'auto',
            prop: 'goods_type',
            sortable: false,
          },
          {
            order: 3,
            label: '商品名称',
            width: 'auto',
            prop: 'goods_name_print',
            sortable: false,
          },
          {
            order: 3.5,
            label: '规格',
            width: 'auto',
            prop: 'specs',
            sortable: false,
          },
          {
            order: 3.6,
            label: '条码',
            width: 'auto',
            prop: 'unit_code',
            sortable: false,
          },
          {
            order: 4,
            label: '单位',
            width: '110', //'100',
            prop: 'unit_name',
            sortable: false,
          },
          {
            order: 5,
            label: '单价',
            width: '100',
            prop: 'goods_price',
            sortable: false,
          },
          {
            order: 6,
            label: '数量',
            width: '100',
            prop: 'quantity',
            sortable: false,
          },
          {
            order: 8,
            label: '毛利',
            width: '110',
            prop: 'profit_amount',
            sortable: false,
          },
          {
            order: 7,
            label: '金额',
            width: '100',
            prop: 'goods_money',
            sortable: false,
          },
          {
            order: 9,
            label: '生产日期',
            width: '110',
            prop: 'goods_production_date',
            sortable: false,
          },
          {
            order: 10,
            label: '备注',
            width: '100',
            prop: 'info',
            sortable: false,
          },
        ],
        middleTableData: [],
        xTableData: [],
      }
    },

    computed: {
      currUnitCode() {
        return (row) => {
          if (row.arr_unit && row.arr_unit.length > 0) {
            let a = row.arr_unit.filter(
              (i) => (i.unit_id || i.id) == row.unit_id
            )[0].unit_code
            return a
          } else {
            return ''
          }
        }
      },
      chck() {
        return this.form.depot_name
      },
      titleTip() {
        if (this.form.pay_status == 1) {
          return (
            '单号:' +
            this.orderCode +
            '     下单: ' +this.form.create_name+
            this.form.create_time +
            (this.form.old_id ? `原单: ${this.form.old_code}` : ' ') +
            ' ' +
            '     结清时间: ' +this.form.edit_name+
            this.form.pay_status_time
          )
        } else {
          return (
            '单号:' +
            this.orderCode +
            (this.form.old_id ? `原单: ${this.form.old_code}` : ' ') +
            ' 下单时间: ' +
            this.form.create_time
          )
        }
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
      orderStatusStr() {
        if (this.orderStatus == '待对账') {
          return 'ddz'
        } else if (this.orderStatus == '已对账') {
          return 'ydz'
        } else if (this.orderStatus == '已作废') {
          return 'yzf'
        } else {
          return ''
        }
      },
    },
    watch: {
      isshowDialog(val) {
        if (val == true) {
          this.getData()
        } else {
          this.loading = true
        }
      },
    },
    methods: {
      rehandler() {
        receiv(this)
      },
      handlerOldcoed() {
        console.log('点击了')
        if (this.form.old_id) {
          this.$refs['checkOrder'].isshowDialog = true
          // this.$refs['checkOrder'].orderStatus = row.bill_status_text
          this.$refs['checkOrder'].id = this.form.old_id
        } else {
        }
      },
      async getData() {
        let { data } = await getDetail({ id: this.id })
        console.log('查看')
        console.log(data)
        this.form = data.order_info
        this.orderCode = this.form.bill_code
        this.form.boss = this.form.boss + ' ' + this.form.mobile
        // 表格
        this.middleTableData = data.order_detail_info
        this.middleTableData.forEach((i) => {
          if (i.goods_type == 6) {
            i.goods_money = -i.goods_money
          }
        })
        // bottom table
        this.xTableData = data.arr_collect
        this.bottomData = data.bill_no
        // 合计
        this.total_num = this.form.total_num
        this.total_money = this.form.total_amount
        // 经办人 + 手机
        this.form.jingbanren =
          this.form.agent_name + ' ' + this.form.agent_mobile
        this.orderStatus = this.form.bill_status_text
        // 处理下方表单显示的样式
        console.log(this.form.receiv_money)
        // style样式选择
        if (
          this.form.sale_type == '1' ||
          this.form.sale_type == '2' ||
          this.form.sale_type == '3'
        ) {
          console.log('1样式')
          this.style = 1
        } else if (this.form.sale_type == '5' || this.form.sale_type == '6') {
          console.log('兑奖样式')
          this.style = 2
        } else if (this.form.sale_type == '4') {
          console.log('兑付样式')
          this.style = 3
        } else {
          this.style = 1
        }
        this.loading = false
      },
      showDialog() {
        this.isshowDialog = true
      },
      close() {
        this.isshowDialog = false
      },
      summaryMethod() {
        return [
          '合计',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          this.total_num,
          this.total_money,
          this.form.gross_profit_amount,
        ]
      },
      // 查看全部单据
      checkAll() {
        console.log('查看全部单据')
      },
      handleSaveRemark() {
        saveRemark({ id: this.id, remark: this.form.remark }).then((res) => {
          this.$message.success('保存成功')
        })
      },
      handlePrint() {
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
      // 查看订单详情
      hanldeOrderDetail(name, id) {
        // 类型包括预订单XD、调拨单DB、收款单SK
        if (name == '预订单') {
          this.$refs.advanceOrder.id = id
          this.$refs.advanceOrder.isshowDialog = true
        } else if (name == '调拨单') {
          let row = { id }
          this.$refs.diaobodanOrder.dialogVisible = true
          this.$refs.diaobodanOrder.type = 1
          this.$refs.diaobodanOrder.handlerlist(row)
        } else if (name == '收款单') {
          let row = { id }
          this.$refs.shoukuanOrder.receipt = true
          this.$refs.shoukuanOrder.handlerData(row)
        } else if (name == '销售单') {
          let row = { id }
          console.log('row', row)
          this.$refs.check.isshowDialog = true
          // this.$refs['checkOrder'].orderStatus = row.bill_status_text
          this.$refs.check.id = row.id
          this.getData()
        }else {
          return false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .wrapper {
    min-height: 800px;
  }
  .setFilter {
    top: 41px;
    left: 7px;
    display: none;
  }
  .bottom-form {
    position: relative;
    padding: 10px;
    border: 1px solid #ccc;
    .b-item {
      margin: 5px 0;
    }
    .checkAll {
      position: absolute;
      top: 50%;
      right: 45px;
      transform: translateY(-50%);
    }
  }
  .orderStatus {
    position: absolute;
    top: 20px;
    right: 40px;
    z-index: 8;
    width: 105px;
    height: 105px;
    font-size: 16px;
    line-height: 105px;
    color: #f60;
    text-align: center;
    border: 2px solid #f60;
    border-radius: 50%;
  }
</style>
